var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-modal',{attrs:{"id":"modal-delete-draft","visible":_vm.value.visible['deleteDraft'],"title":_vm.$t('components.baseDetail.deleteDraftModalTitle'),"cancel-title":_vm.$t('general.cancelModal')},on:{"ok":function($event){return _vm.$emit('delete-draft')},"cancel":function($event){return _vm.hide('deleteDraft')}}},[_c('p',{directives:[{name:"t",rawName:"v-t",value:('components.baseDetail.deleteDraftQuestion'),expression:"'components.baseDetail.deleteDraftQuestion'"}]})]),_c('b-modal',{attrs:{"id":"modal-publish-draft","visible":_vm.value.visible['publishDraft'],"title":_vm.$t('components.baseDetail.publishDraftModalTitle'),"cancel-title":_vm.$t('general.cancelModal')},on:{"ok":function($event){return _vm.$emit('publish')},"cancel":function($event){return _vm.hide('publishDraft')}}},[_c('p',{directives:[{name:"t",rawName:"v-t",value:('components.baseDetail.publishDraftQuestion'),expression:"'components.baseDetail.publishDraftQuestion'"}]})]),_c('b-modal',{attrs:{"id":"modal-delete-entity","visible":_vm.value.visible['deleteEntity'],"title":_vm.$t('components.baseDetail.deleteEntityModalTitle', [
        _vm.value.data.deleteEntityName || ''
      ]),"cancel-title":_vm.$t('general.cancelModal')},on:{"ok":function($event){return _vm.$emit('delete-entity')},"cancel":function($event){return _vm.hide('deleteEntity')}}},[_c('p',{directives:[{name:"t",rawName:"v-t",value:({
        path: 'components.baseDetail.deleteEntityQuestion',
        args: [_vm.value.data.deleteEntityName || '']
      }),expression:"{\n        path: 'components.baseDetail.deleteEntityQuestion',\n        args: [value.data.deleteEntityName || '']\n      }"}]})]),_c('b-modal',{attrs:{"id":"modal-unsaved-changes","visible":_vm.value.visible['unsavedChanges'],"title":_vm.$t('components.baseDetail.unsavedChangesTitle'),"cancel-title":_vm.$t('general.cancelModal')},on:{"cancel":function($event){_vm.hide('unsavedChanges');
      _vm.$emit('cancel-leave');},"ok":function($event){_vm.hide('unsavedChanges');
      _vm.$emit('perform-leave');}}},[_c('p',{directives:[{name:"t",rawName:"v-t",value:({
        path: 'components.baseDetail.unsavedChangesQuestion'
      }),expression:"{\n        path: 'components.baseDetail.unsavedChangesQuestion'\n      }"}]})])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }