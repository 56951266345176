<template>
  <div>
    <b-modal
      id="modal-delete-draft"
      :visible="value.visible['deleteDraft']"
      :title="$t('components.baseDetail.deleteDraftModalTitle')"
      :cancel-title="$t('general.cancelModal')"
      @ok="$emit('delete-draft')"
      @cancel="hide('deleteDraft')"
    >
      <p v-t="'components.baseDetail.deleteDraftQuestion'" />
    </b-modal>

    <b-modal
      id="modal-publish-draft"
      :visible="value.visible['publishDraft']"
      :title="$t('components.baseDetail.publishDraftModalTitle')"
      :cancel-title="$t('general.cancelModal')"
      @ok="$emit('publish')"
      @cancel="hide('publishDraft')"
    >
      <p v-t="'components.baseDetail.publishDraftQuestion'" />
    </b-modal>

    <b-modal
      id="modal-delete-entity"
      :visible="value.visible['deleteEntity']"
      :title="
        $t('components.baseDetail.deleteEntityModalTitle', [
          value.data.deleteEntityName || ''
        ])
      "
      :cancel-title="$t('general.cancelModal')"
      @ok="$emit('delete-entity')"
      @cancel="hide('deleteEntity')"
    >
      <p
        v-t="{
          path: 'components.baseDetail.deleteEntityQuestion',
          args: [value.data.deleteEntityName || '']
        }"
      />
    </b-modal>

    <b-modal
      id="modal-unsaved-changes"
      :visible="value.visible['unsavedChanges']"
      :title="$t('components.baseDetail.unsavedChangesTitle')"
      :cancel-title="$t('general.cancelModal')"
      @cancel="
        hide('unsavedChanges');
        $emit('cancel-leave');
      "
      @ok="
        hide('unsavedChanges');
        $emit('perform-leave');
      "
    >
      <p
        v-t="{
          path: 'components.baseDetail.unsavedChangesQuestion'
        }"
      />
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "BaseDetailModals",
  props: {
    value: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    hide(type) {
      // Clone value object
      let changedValue = { ...this.value };
      // Modify cloned object
      changedValue.visible[type] = false;
      // Emit changed object as new value
      this.$emit("input", changedValue);
    }
  }
};
</script>
